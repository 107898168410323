@use '@carbon/styles/scss/theme';
@use '../globals';
@use '../tokens';

.#{globals.$prefix}--#{globals.$charts-prefix}--alluvial {
	rect.node,
	rect.node-text-bg {
		fill: tokens.$layer-inverse-absolute;
	}

	text.node-text {
		fill: tokens.$layer-01-absolute;
	}

	polygon.arrow-down {
		fill: theme.$layer-01;
	}
}
