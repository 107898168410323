@use '@carbon/styles/scss/theme';
@use '@carbon/colors';
@use '../globals';
@use '../tokens';

$lines-bg-color: theme.$layer-accent-01;
$lines-shimmer-color: colors.$white-0;
$areas-bg-color: rgba(127, 127, 127, 0.1);
$areas-shimmer-color: rgba(colors.$white-0, 0.15);

.#{globals.$prefix}--#{globals.$charts-prefix}--skeleton-lines {
	// lines
	rect.chart-skeleton-backdrop {
		fill: tokens.$grid-bg;
	}

	.shimmer-effect-lines {
		stroke-width: 1px;
	}

	.shimmer-effect-sparkline {
		stroke-width: 0px;
	}

	.empty-state-lines {
		stroke-width: 1px;
		stroke: $lines-bg-color;
	}

	.shimmer-lines {
		.stop-bg-shimmer {
			stop-color: $lines-bg-color;
		}

		.stop-shimmer {
			stop-color: $lines-shimmer-color;
		}
	}
}
