@use '../globals';
@use '../tokens';

svg.#{globals.$prefix}--#{globals.$charts-prefix}--color-legend {
	display: flex;
	user-select: none;

	g.legend-title text {
		fill: tokens.$layer-inverse-absolute;
	}
}
