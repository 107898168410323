@use '@carbon/styles/scss/motion' as motion;
@use '@carbon/styles/scss/theme';
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--scatter {
	circle.dot.hovered {
		fill-opacity: 1;
		transition: all 0.1s;
		@include motion.motion(standard, expressive);
	}

	circle.dot.unfilled {
		fill: theme.$layer-01;
		stroke-width: 1.5;
	}

	circle.dot.threshold-anomaly {
		stroke-width: 3;
	}

	g.lines path.line {
		mix-blend-mode: multiply;
	}
}
