@use '@carbon/themes/scss/generated/tokens' as theme;
@use '@carbon/styles/scss/components/button/tokens' as button;
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--grid-brush {
	// disable default d3 brush selection
	g.grid-brush {
		rect.selection {
			fill: none;
			fill-opacity: 0;
			stroke: none;
		}
	}
}

.#{globals.$prefix}--#{globals.$charts-prefix}--grid-brush {
	rect.frontSelection {
		fill: theme.$layer-accent-01;
		fill-opacity: 0.3;
		stroke: button.$button-tertiary;
	}
}
