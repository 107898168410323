@use '@carbon/styles/scss/theme';
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--heatmap {
	g.highlighter-hidden {
		visibility: hidden;
	}

	g.cell-highlight {
		line {
			stroke: white;
			stroke-width: 1px;
		}
	}

	g.cell-2 {
		line {
			stroke: white;
			stroke-width: 2px !important;
		}
	}

	g.multi-cell {
		line {
			stroke: white;
			stroke-width: 2px;
		}
	}

	rect.pattern-fill {
		fill: theme.$border-strong-01;
	}

	g.shadows {
		line.top {
			filter: drop-shadow(0px -3px 2px black);
		}

		line.down {
			filter: drop-shadow(0px 3px 2px black);
		}

		line.left {
			filter: drop-shadow(-3px 0px 2px black);
		}

		line.right {
			filter: drop-shadow(3px 0px 2px black);
		}
	}

	rect.heat {
		stroke-width: 0px;
		stroke: theme.$background;
	}

	rect.null-state {
		fill: theme.$icon-inverse;
	}
}
