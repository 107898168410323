@use 'sass:map';
@use '@carbon/styles/scss/themes';
@use '@carbon/colors';
@use '@carbon/styles/scss/utilities/component-tokens';

// THEMES
$color-scheme: (
	fallback: light,
	values: (
		(
			theme: themes.$white,
			value: light
		),
		(
			theme: themes.$g10,
			value: light
		),
		(
			theme: themes.$g90,
			value: dark
		),
		(
			theme: themes.$g100,
			value: dark
		)
	)
) !default;

// ELEMENTS
$alert-stroke: (
	fallback: colors.$yellow-50,
	values: (
		(
			theme: themes.$white,
			value: colors.$yellow-50
		),
		(
			theme: themes.$g10,
			value: colors.$yellow-50
		),
		(
			theme: themes.$g90,
			value: none
		),
		(
			theme: themes.$g100,
			value: none
		)
	)
) !default;

$layer-01-absolute: (
	fallback: colors.$white-0,
	values: (
		(
			theme: themes.$white,
			value: colors.$white-0
		),
		(
			theme: themes.$g10,
			value: colors.$white-0
		),
		(
			theme: themes.$g90,
			value: colors.$black-100
		),
		(
			theme: themes.$g100,
			value: colors.$black-100
		)
	)
) !default;

$layer-inverse-absolute: (
	fallback: colors.$black-100,
	values: (
		(
			theme: themes.$white,
			value: colors.$black-100
		),
		(
			theme: themes.$g10,
			value: colors.$black-100
		),
		(
			theme: themes.$g90,
			value: colors.$white-0
		),
		(
			theme: themes.$g100,
			value: colors.$white-0
		)
	)
) !default;

$null-state: (
	fallback: none,
	values: (
		(
			theme: themes.$white,
			value: none
		),
		(
			theme: themes.$g10,
			value: none
		),
		(
			theme: themes.$g90,
			value: map.get(themes.$g90, icon-inverse)
		),
		(
			theme: themes.$g100,
			value: none
		)
	)
) !default;

// GRID
$grid-bg: (
	fallback: map.get(themes.$white, background),
	values: (
		(
			theme: themes.$white,
			value: map.get(themes.$white, background)
		),
		(
			theme: themes.$g10,
			value: map.get(themes.$g10, layer-01)
		),
		(
			theme: themes.$g90,
			value: colors.$gray-100
		),
		(
			theme: themes.$g100,
			value: map.get(themes.$g100, background)
		)
	)
) !default;

// METER
$meter-range-indicator: (
	fallback: colors.$gray-40,
	values: (
		(
			theme: themes.$white,
			value: colors.$gray-40
		),
		(
			theme: themes.$g10,
			value: colors.$gray-40
		),
		(
			theme: themes.$g90,
			value: colors.$gray-60
		),
		(
			theme: themes.$g100,
			value: colors.$gray-60
		)
	)
) !default;

// NETWORK DIAGRAMS
$network-diagrams-background-hover: (
	fallback: #f1f1f1,
	values: (
		(
			theme: themes.$white,
			value: #f1f1f1
		),
		(
			theme: themes.$g10,
			value: #f1f1f1
		),
		(
			theme: themes.$g90,
			value: #ededed
		),
		(
			theme: themes.$g100,
			value: #ededed
		)
	)
) !default;

// TOOLTIP
$tooltip-line-border: (
	fallback: map.get(themes.$white, layer-accent-01),
	values: (
		(
			theme: themes.$white,
			value: map.get(themes.$white, layer-accent-01)
		),
		(
			theme: themes.$g10,
			value: map.get(themes.$g10, layer-accent-01)
		),
		(
			theme: themes.$g90,
			value: map.get(themes.$g90, layer-01)
		),
		(
			theme: themes.$g100,
			value: map.get(themes.$g100, border-strong-01)
		)
	)
) !default;

// ZONES
$zone-fill-01: (
	fallback: colors.$gray-10,
	values: (
		(
			theme: themes.$white,
			value: colors.$gray-10
		),
		(
			theme: themes.$g10,
			value: colors.$gray-10
		),
		(
			theme: themes.$g90,
			value: colors.$gray-90
		),
		(
			theme: themes.$g100,
			value: colors.$gray-90
		)
	)
) !default;
$zone-stroke-01: (
	fallback: colors.$gray-50,
	values: (
		(
			theme: themes.$white,
			value: colors.$gray-50
		),
		(
			theme: themes.$g10,
			value: colors.$gray-50
		),
		(
			theme: themes.$g90,
			value: colors.$gray-60
		),
		(
			theme: themes.$g100,
			value: colors.$gray-60
		)
	)
) !default;

$zone-fill-02: (
	fallback: colors.$gray-20,
	values: (
		(
			theme: themes.$white,
			value: colors.$gray-20
		),
		(
			theme: themes.$g10,
			value: colors.$gray-20
		),
		(
			theme: themes.$g90,
			value: colors.$gray-80
		),
		(
			theme: themes.$g100,
			value: colors.$gray-80
		)
	)
) !default;
$zone-stroke-02: (
	fallback: colors.$gray-50,
	values: (
		(
			theme: themes.$white,
			value: colors.$gray-50
		),
		(
			theme: themes.$g10,
			value: colors.$gray-50
		),
		(
			theme: themes.$g90,
			value: colors.$gray-60
		),
		(
			theme: themes.$g100,
			value: colors.$gray-60
		)
	)
) !default;

$zone-fill-03: (
	fallback: colors.$gray-30,
	values: (
		(
			theme: themes.$white,
			value: colors.$gray-30
		),
		(
			theme: themes.$g10,
			value: colors.$gray-30
		),
		(
			theme: themes.$g90,
			value: colors.$gray-70
		),
		(
			theme: themes.$g100,
			value: colors.$gray-70
		)
	)
) !default;
$zone-stroke-03: (
	fallback: colors.$gray-50,
	values: (
		(
			theme: themes.$white,
			value: colors.$gray-50
		),
		(
			theme: themes.$g10,
			value: colors.$gray-50
		),
		(
			theme: themes.$g90,
			value: colors.$gray-60
		),
		(
			theme: themes.$g100,
			value: colors.$gray-60
		)
	)
) !default;

$custom-charting-tokens: (
	// DARK/LIGHT THEME
	color-scheme: $color-scheme,
	// ELEMENTS
	alert-stroke: $alert-stroke,
	layer-01-absolute: $layer-01-absolute,
	layer-inverse-absolute: $layer-inverse-absolute,
	null-state: $null-state,
	// GRID
	grid-bg: $grid-bg,
	// METER
	meter-range-indicator: $meter-range-indicator,
	// NETWORK DIAGRAMS
	network-diagrams-background-hover: $network-diagrams-background-hover,
	// TOOLTIP
	tooltip-line-border: $tooltip-line-border,
	// ZONES
	zone-fill-01: $zone-fill-01,
	zone-stroke-01: $zone-stroke-01,
	zone-fill-02: $zone-fill-02,
	zone-stroke-02: $zone-stroke-02,
	zone-fill-03: $zone-fill-03,
	zone-stroke-03: $zone-stroke-03
);

// DARK/LIGHT THEME
$color-scheme: component-tokens.get-var($color-scheme, 'color-scheme');

// ELEMENTS
$alert-stroke: component-tokens.get-var($alert-stroke, 'alert-stroke');
$layer-01-absolute: component-tokens.get-var($layer-01-absolute, 'layer-01-absolute');
$layer-inverse-absolute: component-tokens.get-var(
	$layer-inverse-absolute,
	'layer-inverse-absolute'
);
$null-state: component-tokens.get-var($null-state, 'null-state');

// GRID
$grid-bg: component-tokens.get-var($grid-bg, 'grid-bg');

// METER
$meter-range-indicator: component-tokens.get-var($meter-range-indicator, 'meter-range-indicator');

// NETWORK DIAGRAMS
$network-diagrams-background-hover: component-tokens.get-var(
	$network-diagrams-background-hover,
	'network-diagrams-background-hover'
);

// TOOLTIP
$tooltip-line-border: component-tokens.get-var($tooltip-line-border, 'tooltip-line-border');

// ZONES
$zone-fill-01: component-tokens.get-var($zone-fill-01, 'zone-fill-01');
$zone-stroke-01: component-tokens.get-var($zone-stroke-01, 'zone-stroke-01');
$zone-fill-02: component-tokens.get-var($zone-fill-02, 'zone-fill-02');
$zone-stroke-02: component-tokens.get-var($zone-stroke-02, 'zone-stroke-02');
$zone-fill-03: component-tokens.get-var($zone-fill-03, 'zone-fill-03');
$zone-stroke-03: component-tokens.get-var($zone-stroke-03, 'zone-stroke-03');
