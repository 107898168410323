@use '@carbon/colors';
@use '@carbon/styles/scss/theme';
@use '../globals';
@use '../tokens';

.#{globals.$prefix}--#{globals.$charts-prefix}--meter-title {
	overflow: visible;

	text.meter-title,
	text.proportional-meter-title,
	text.proportional-meter-total,
	text.percent-value {
		fill: theme.$text-primary;
	}

	g.status-indicator {
		&.status--danger circle.status {
			fill: theme.$support-error;
		}

		&.status--warning {
			circle.status {
				fill: theme.$support-warning;
			}

			path.innerFill {
				fill: colors.$black-100;
			}
		}

		&.status--success circle.status {
			fill: theme.$support-success;
		}

		path.innerFill {
			fill: tokens.$layer-01-absolute;
		}
	}
}
