@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--layout {
	&-row {
		display: flex;
		flex-direction: row;
	}

	&-column {
		display: flex;
		flex-direction: column;
	}

	&-row-reverse {
		display: flex;
		flex-direction: row-reverse;
	}

	&-column-reverse {
		display: flex;
		flex-direction: column-reverse;
	}

	&-alignitems-center {
		align-items: center;
	}
}

.#{globals.$prefix}--#{globals.$charts-prefix}--chart-wrapper .layout-child {
	overflow: visible;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--chart-wrapper svg.layout-svg-wrapper {
	height: inherit;
	width: inherit;
	overflow: visible;
}
