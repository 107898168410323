@use '@carbon/styles/scss/utilities/focus-outline' as focus;
@use '@carbon/styles/scss/spacing';
@use '@carbon/styles/scss/type';
@use '@carbon/styles/scss/theme';
@use '../../globals';
@use '../../tokens';

@mixin stacked-card-node {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 0;
	border-top: 0.125rem solid theme.$layer-accent-01;
	border-right: 0.125rem solid theme.$layer-accent-01;
	pointer-events: none;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node {
	display: flex;
	position: relative;
	background-color: theme.$layer-01;
	z-index: 1;
	box-sizing: border-box;
	font-family: var(--#{globals.$prefix}-charts-font-family);
	width: 100%;
	height: 100%;
	padding: spacing.$spacing-05 spacing.$spacing-03;
	border-left: 0.25rem solid theme.$border-inverse;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node--a,
.#{globals.$prefix}--#{globals.$charts-prefix}--card-node--button {
	border-top: none;
	border-right: none;
	border-bottom: none;
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node--button {
	font-family: var(--#{globals.$prefix}-charts-font-family);
	text-align: left;
	width: 100%;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node--a:focus,
.#{globals.$prefix}--#{globals.$charts-prefix}--card-node--a:hover,
.#{globals.$prefix}--#{globals.$charts-prefix}--card-node--button:focus,
.#{globals.$prefix}--#{globals.$charts-prefix}--card-node--button:hover {
	background-color: tokens.$network-diagrams-background-hover;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node--a:focus,
.#{globals.$prefix}--#{globals.$charts-prefix}--card-node--button:focus {
	@include focus.focus-outline($type: outline);
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node--stacked {
	&::before {
		@include stacked-card-node();
		left: 0.3125rem;
		bottom: 0.3125rem;
	}

	&::after {
		@include stacked-card-node();
		left: 0.6875rem;
		bottom: 0.6875rem;
	}
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node__column {
	padding: 0 spacing.$spacing-03;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node__column--farside {
	margin-left: auto;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node__title {
	@include type.type-style($name: productive-heading-01);
	margin: 0;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node__subtitle {
	@include type.type-style($name: body-short-01);
	margin: 0;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--card-node__label {
	display: block;
	@include type.type-style($name: label-01);
	color: theme.$text-secondary;
	padding-top: spacing.$spacing-06;
}
