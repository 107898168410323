@use '../globals';
@use '@carbon/colors';

.#{globals.$prefix}--#{globals.$charts-prefix}--highlight {
	rect.highlight-bar {
		pointer-events: none;
		fill: colors.$magenta-50;
		stroke: colors.$magenta-50;
	}
}
