@use '@carbon/styles/scss/theme';
@use '@carbon/colors';
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--zoom-bar {
	rect.zoom-bg {
		fill: theme.$background;
		stroke: theme.$layer-01;
	}

	rect.zoom-slider-bg {
		fill: theme.$layer-01;
	}

	rect.zoom-slider-selected-area {
		fill: theme.$icon-secondary;
	}

	path.zoom-bg-baseline {
		stroke: theme.$border-strong-01;
		stroke-width: 2;
	}

	path.zoom-graph-area {
		fill: theme.$layer-accent-01;
		stroke: theme.$border-strong-01;
		stroke-width: 1;
	}

	path.zoom-graph-area-unselected {
		fill: theme.$layer-01;
		stroke: none;
	}

	g.zoom-bar-brush {
		rect.handle {
			fill: theme.$icon-secondary;
		}

		rect.handle-bar {
			fill: theme.$layer-02;
		}

		// clear d3.brush selection style
		rect.selection {
			fill: none;
			stroke: none;
		}
	}

	rect[class^='highlight-'] {
		fill: colors.$magenta-50;
		stroke: colors.$magenta-50;
	}
}
