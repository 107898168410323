@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--lollipop {
	line.line {
		pointer-events: none;
	}

	circle.dot {
		stroke-width: 1.5;
	}
}
