@use '@carbon/styles/scss/theme';
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--gauge {
	overflow: visible;

	path.arc-background {
		fill: theme.$layer-01;
	}

	.gauge-delta-arrow {
		&.status--danger {
			fill: theme.$support-error;
		}

		&.status--warning {
			fill: theme.$support-warning;
		}

		&.status--success {
			fill: theme.$support-success;
		}
	}
}
