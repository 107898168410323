@use '@carbon/styles/scss/theme';
@use '../globals';
@use '../tokens';

.#{globals.$prefix}--#{globals.$charts-prefix}--meter {
	rect.container {
		fill: theme.$layer-01;
	}

	line.rangeIndicator {
		stroke: tokens.$meter-range-indicator;
		stroke-width: 1px;
	}

	rect.value {
		&.status--danger {
			fill: theme.$support-error;
		}

		&.status--warning {
			fill: theme.$support-warning;
			stroke-width: 1px;
			stroke: tokens.$alert-stroke;
		}

		&.status--success {
			fill: theme.$support-success;
		}
	}

	line.peak {
		stroke: theme.$border-inverse;
		stroke-width: 2px;
	}
}
