@use '@carbon/themes/scss/generated/tokens' as theme;
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--title {
	p.title {
		color: theme.$text-primary;
		font-size: 16px;
		font-family: var(--#{globals.$prefix}-charts-font-family);
		// TODO-V11
		// font-weight: font-weight('semibold');
		font-weight: 600;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 15px;
	}
}

.#{globals.$prefix}--chart-holder {
	.layout-child.title {
		height: unset !important;
		overflow: hidden;
	}
}
