@use '@carbon/styles/scss/spacing';
@use '@carbon/styles/scss/type';
@use '@carbon/styles/scss/theme';
@use '../../globals';
@use '../../tokens';

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: theme.$layer-01;
	box-sizing: border-box;
	font-family: var(--#{globals.$prefix}-charts-font-family);
	width: 100%;
	height: 100%;
	position: relative;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--circle {
	border-radius: 100%;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--square {
	border-radius: 0;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--rounded-square {
	border-radius: 0.5rem;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--a,
.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--button {
	border: none;
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--button {
	font-family: var(--#{globals.$prefix}-charts-font-family);
	text-align: left;
	width: 100%;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--a:focus,
.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--a:hover,
.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--button:focus,
.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--button:hover {
	background-color: tokens.$network-diagrams-background-hover;

	.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node__title,
	.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node__subtitle {
		// TODO-V11
		// font-weight: font-weight('semibold');
		font-weight: 600;
	}
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--a:focus,
.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node--button:focus {
	&:focus {
		outline: none;
		box-shadow: 0 0 0 2px theme.$focus;
	}
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node__body {
	position: absolute;
	top: calc(100% + #{spacing.$spacing-01});
	text-align: center;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node__subtitle {
	padding-bottom: spacing.$spacing-01;
	@include type.type-style($name: body-short-01);
	color: theme.$text-primary;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node__icon {
	display: flex;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--shape-node__title {
	@include type.type-style($name: body-short-01);
	color: theme.$text-primary;
	padding-top: spacing.$spacing-01;
	margin-bottom: 1px;
}
