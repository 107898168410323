@use '@carbon/styles/scss/theme';
@use '@carbon/layout';
@use 'globals';

.#{globals.$prefix}--chart-holder {
	--#{globals.$prefix}-charts-font-family: #{globals.$font-family};
	--#{globals.$prefix}-charts-font-family-condensed: #{globals.$font-family-condensed};
	font-family: var(--#{globals.$prefix}-charts-font-family);
}

.#{globals.$prefix}--#{globals.$charts-prefix}--chart-wrapper {
	p {
		padding: 0;
		margin: 0;
		font-size: 12px;
		// TODO-V11
		// font-weight: font-weight('regular');
		font-weight: 400;
		color: theme.$text-secondary;
	}

	text {
		font-size: 12px;
		// TODO-V11
		// font-weight: font-weight('regular');
		font-weight: 400;
		fill: theme.$text-secondary;
	}

	g.gauge-numbers text.gauge-value-number {
		font-family: var(--#{globals.$prefix}-charts-font-family);
		// TODO-V11
		// font-weight: font-weight('light');
		font-weight: 300;
	}

	text.meter-title,
	text.percent-value {
		font-size: layout.$base-font-size;
		font-family: var(--#{globals.$prefix}-charts-font-family);
	}

	text.meter-title {
		// TODO-V11
		// font-weight: font-weight('semibold');
		font-weight: 600;
	}
}
