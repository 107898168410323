@use '@carbon/styles/scss/theme';
@use '@carbon/colors';
@use '../globals';

$lines-bg-color: theme.$layer-accent-01;
$lines-shimmer-color: colors.$white-0;

.#{globals.$prefix}--#{globals.$charts-prefix}--line {
	path.line {
		pointer-events: none;
		fill: none;
		stroke-width: 1.5;
	}

	path.line.sparkline-loading {
		animation: shimmer 2.5s infinite linear;
		@keyframes shimmer {
			0% {
				stroke: $lines-bg-color;
			}
			20% {
				stroke: $lines-shimmer-color;
				opacity: 0.5;
			}
			100% {
				stroke: $lines-bg-color;
			}
		}
	}
}
