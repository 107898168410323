@use '@carbon/styles/scss/theme';
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--choropleth {
	path.border {
		stroke: theme.$border-subtle-selected-01;
	}

	g.missing-data path {
		stroke: theme.$border-subtle-selected-01;
		fill: theme.$background;
	}

	pattern path.pattern-fill {
		stroke: theme.$border-strong-01;
		stroke-width: 0.5px;
	}
}
