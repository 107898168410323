@use '@carbon/styles/scss/spacing';
@use '@carbon/styles/scss/theme';
@use '../../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--edge {
	fill: transparent;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--edge__container {
	stroke-width: spacing.$spacing-06;
	stroke: transparent;
	stroke-dasharray: none;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--edge__inner {
	stroke-width: 0.0625rem;
	stroke: theme.$border-strong-01;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--edge__outer {
	stroke-width: 0.15625rem;
	stroke: transparent;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--edge:hover {
	.#{globals.$prefix}--#{globals.$charts-prefix}--edge__inner {
		stroke-width: 0.15625rem;
	}
}

.#{globals.$prefix}--#{globals.$charts-prefix}--edge--dash-sm {
	stroke-dasharray: 2 4;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--edge--dash-md {
	stroke-dasharray: 4 4;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--edge--dash-lg {
	stroke-dasharray: 8 4;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--edge--dash-xl {
	stroke-dasharray: 16 4;
}

.#{globals.$prefix}--#{globals.$charts-prefix}--edge--tunnel {
	.#{globals.$prefix}--#{globals.$charts-prefix}--edge__outer {
		stroke: theme.$layer-accent-01;
		stroke-width: 0.375rem;
	}
}

.#{globals.$prefix}--#{globals.$charts-prefix}--edge--double {
	.#{globals.$prefix}--#{globals.$charts-prefix}--edge__inner {
		stroke: theme.$background;
	}

	.#{globals.$prefix}--#{globals.$charts-prefix}--edge__outer {
		stroke: theme.$border-inverse;
		stroke-width: 0.28125rem;
	}
}
