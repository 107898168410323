@use '@carbon/styles/scss/motion';
@use '@carbon/styles/scss/theme';
@use '@carbon/colors';
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--threshold {
	line.threshold-line {
		stroke: colors.$red-50;
		stroke-width: 1;
		stroke-dasharray: 4;
		cursor: pointer;
		pointer-events: none;
	}

	line.threshold-line.active {
		stroke-width: 2;
	}

	rect.threshold-hoverable-area {
		height: 20px;
		transform: translate(0, -10px);
		cursor: pointer;
		fill: transparent;

		&.rotate {
			transform: rotate(90deg) translate(0, -10px);
		}
	}
}

.#{globals.$prefix}--#{globals.$charts-prefix}--threshold--label {
	background-color: colors.$red-50;
	pointer-events: none;
	transition: opacity 0.1s;
	@include motion.motion(standard, expressive);
	display: inline;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
	position: absolute;
	word-wrap: break-word;
	z-index: 1059;
	font-family: var(--#{globals.$prefix}-charts-font-family-condensed);

	color: theme.$text-primary;
	line-height: 16px;
	font-size: 12px;
	padding: 4px;
	min-width: 20px;

	&.hidden {
		opacity: 0;
		transition: opacity 0.1s;
		@include motion.motion(standard, expressive);
	}
}
