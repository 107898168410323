@use 'sass:map';
@use '@angular/material' as mat;

// Note: Color palettes are generated from primary: #086cb3, secondary: #30aa4b, tertiary: #30aa4b
$_palettes: (
  primary: (
    0: #fdf1e5,
    10: #fbdbbd,
    20: #f8c492,
    25: #f5ac66,
    30: #f39a45,
    35: #f18824,
    40: #ed751b,
    50: #ed751b,
    60: #eb6b16,
    70: #e7580d,
    80: #ffffff,
    90: #ed751b,
    95: #FFEDE3,
    98: #FFF8F5,
    99: #ffc5ac,
    100: #ffb392    
  ),
  secondary: (
    0: #e8e9eb,
    10: #c5c8cc,
    20: #9fa4aa,
    25: #787f88,
    30:  #5b636f,
    35: #3e4855,
    40: #38414e,
    50: #303844,
    60: #28303b,
    70: #1b212a,
    80: #71a8ff,
    90: #3e88ff,
    95: #0b69ff,
    98: #F8F9FF,
    99: #FDFCFF,
    100: #FFFFFF
  ),
  tertiary: (
    0: #e8e9eb,
    10: #c5c8cc,
    20: #9fa4aa,
    25: #787f88,
    30: #5b636f,
    35: #3e4855,
    40: #38414e,
    50: #303844,
    60: #28303b,
    70: #1b212a,
    80: #71a8ff,
    90: #3e88ff,
    95: #0b69ff,
    98: #F8F9FF,
    99: #FDFCFF,
    100: #FFFFFF
  ),
  neutral: (
    0: #000000,
    10: #1a1c1e,
    20: #2f3033,
    25: #3a3b3e,
    30: #45474a,
    35: #515255,
    40: #5d5e61,
    50: #76777a,
    60: #909094,
    70: #ababae,
    80: #c6c6ca,
    90: #e2e2e6,
    95: #f1f0f4,
    98: #faf9fc,
    99: #fdfcff,
    100: #ffffff,
    4: #0d0e11,
    6: #121316,
    12: #1e2022,
    17: #282a2d,
    22: #333538,
    24: #38393c,
    87: #dadadd,
    92: #e8e8eb,
    94: #eeedf1,
    96: #f4f3f7,
  ),
  neutral-variant: (
    0: #000000,
    10: #171c22,
    20: #2c3137,
    25: #373c43,
    30: #42474e,
    35: #4e535a,
    40: #5a5f66,
    50: #73777f,
    60: #8d9199,
    70: #a7abb4,
    80: #c3c7cf,
    90: #dfe2eb,
    95: #edf1fa,
    98: #f8f9ff,
    99: #fdfcff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$myapp-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary,
  )
));

:root {
  @include mat.all-component-themes($myapp-theme);
}

@include mat.color-variants-backwards-compatibility($myapp-theme);
@include mat.typography-hierarchy($myapp-theme);