@use '@carbon/styles/scss/theme';
@use '@carbon/colors';
@use '../globals';

div.#{globals.$prefix}--#{globals.$charts-prefix}--legend {
	font-family: var(--#{globals.$prefix}-charts-font-family-condensed);
	display: flex;
	user-select: none;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;

	&[data-name='legend-items'] {
		width: 100%;
		margin: -5px;
	}

	div.legend-item {
		display: flex;
		align-items: center;
		margin: 5px;

		div.checkbox {
			width: 13px;
			height: 13px;
			margin-right: 4px;
			border-radius: 2px;
			border: solid 1px theme.$background;
			box-shadow: 0 0 0 2px transparent;

			@media (forced-colors: active) {
				forced-color-adjust: none;
			}

			&:not(.active) {
				border-color: theme.$text-secondary;
				background: theme.$background;
			}

			svg {
				display: none;
				vertical-align: text-top;
				fill: theme.$background;
				stroke: theme.$background;
			}
		}

		&.additional {
			svg.icon {
				margin-right: 4px;
			}
		}

		p {
			font-size: 12px;
			fill: theme.$text-secondary;
			line-height: 1rem;
		}
	}

	&.center-aligned {
		justify-content: center;
	}

	&.right-aligned {
		justify-content: flex-end;
	}

	&.has-deactivated-items {
		div.legend-item {
			div.checkbox {
				svg {
					display: block;
				}
			}
		}
	}

	&.vertical {
		margin: -5px;
		flex-direction: column;

		div.legend-item {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}

	&.clickable div.legend-item:not(.additional):hover {
		cursor: pointer;

		div.checkbox {
			border: solid 1px theme.$background;
			box-shadow: 0 0 0 2px colors.$blue-60;

			&:not(.active) {
				border-color: theme.$text-secondary;
			}
		}
	}
}
