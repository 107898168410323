@use '@carbon/styles/scss/motion';
@use '@carbon/styles/scss/theme';
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--bubble {
	circle.dot.hovered {
		fill-opacity: 1;
		transition: all 0.1s;
		@include motion.motion(standard, expressive);
	}

	circle.dot.unfilled {
		fill: theme.$layer-01;
	}

	g.lines path.line {
		mix-blend-mode: multiply;
	}
}
