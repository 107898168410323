@use '../globals';
@use '@carbon/styles/scss/theme';

.#{globals.$prefix}--#{globals.$charts-prefix}--axes {
	font-family: var(--#{globals.$prefix}-charts-font-family-condensed);
	overflow: visible;

	g.axis {
		g.ticks.invisible {
			visibility: hidden;
		}

		g.tick-hover rect.axis-holder {
			fill: transparent;
			stroke: transparent;
			stroke-width: 2px;
		}

		g.tick-hover:hover,
		g.tick-hover:focus {
			rect.axis-holder {
				fill: theme.$layer-selected-inverse;
				stroke: theme.$layer-selected-inverse;
				stroke-width: 2px;
			}

			text {
				fill: theme.$layer-selected;
			}
		}

		g.tick text {
			fill: theme.$text-secondary;
			font-family: var(--#{globals.$prefix}-charts-font-family-condensed);
		}

		g.tick line {
			display: none;
		}

		path.domain {
			stroke: theme.$border-strong-01;
		}

		.axis-title {
			font-family: var(--#{globals.$prefix}-charts-font-family);
			// TODO-V11
			// font-weight: font-weight('semibold');
			font-weight: 600;
			fill: theme.$text-primary;
		}
	}
}
