@use '../globals';
@use '../tokens';

.#{globals.$prefix}--#{globals.$charts-prefix}--bullet {
	path.range-box {
		pointer-events: none;

		&.order-1 {
			fill: tokens.$zone-fill-01;
			stroke: tokens.$zone-stroke-01;
		}

		&.order-2 {
			fill: tokens.$zone-fill-02;
			stroke: tokens.$zone-stroke-02;
		}

		&.order-3 {
			fill: tokens.$zone-fill-03;
			stroke: tokens.$zone-stroke-03;
		}
	}

	path.marker,
	path.quartile {
		pointer-events: none;
		stroke-width: 1.5px;
		stroke: tokens.$layer-inverse-absolute;
	}

	path.quartile.over-bar {
		stroke: tokens.$layer-01-absolute;
	}
}
