@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--wordcloud {
	text.word {
		&.light {
			// TODO-V11
			// font-weight: font-weight('light');
			font-weight: 300;
		}
	}
}
