@use '@carbon/styles/scss/theme';
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--circle-pack {
	circle.node {
		stroke-width: 1.5px;

		&.hovered {
			fill-opacity: 1;
		}

		&.non-focal {
			fill: theme.$icon-disabled;
			fill-opacity: 30%;
			stroke: theme.$icon-disabled;
		}

		&.clickable {
			cursor: zoom-in;
		}
	}
}

.#{globals.$prefix}--#{globals.$charts-prefix}--chart-wrapper.zoomed-in {
	cursor: zoom-out;
	.#{globals.$prefix}--#{globals.$charts-prefix}--circle-pack {
		circle.node {
			&.hovered-child {
				stroke: 1.5px solid initial;
			}

			&.clickable {
				cursor: zoom-out;
			}
		}
	}
}
