/* You can add global styles to this file, and also import other style files */
//@import 'app-theme';
//@import '_variables';
@use '@angular/material' as mat;
@include mat.core();

@import './theme.scss';

//Imports for WYSIWYG Editor
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.bubble.css';
@import "@carbon/charts/styles.css";
@import '@material-design-icons/font';



@font-face {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 900;
  src: url('../src/assets/font/Roboto/Roboto-Black.ttf'); 
}

@font-face {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 700;
  src: url('../src/assets/font/Roboto/Roboto-Bold.ttf'); 
}


@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../src/assets/font/Roboto/Roboto-Medium.ttf'); 
}



@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/font/Roboto/Roboto-Regular.ttf'); 
}


@font-face {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 300;
  src: url('../src/assets/font/Roboto/Roboto-Light.ttf'); 
}

@font-face {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 200;
  src: url('../src/assets/font/Roboto/Roboto-Light.ttf'); 
}

@font-face {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 100;
  src: url('../src/assets/font/Roboto/Roboto-Thin.ttf'); 
}





span.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
  --mdc-chip-label-text-color: #fff;
}

.mat-mdc-menu-item{
  padding: 10px 16px !important;
}

.mdc-card__actions{
  padding: 16px 
}



$sm  : 576px;
$md  : 768px;
$lg  : 992px;
$xl  : 1200px;
$xxl : 1400px;


html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f9f9f9;
  min-height: 100vh;
 // color: $dark;
  font-family: 'Roboto' !important;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.main-container {
  margin-top: 40px;
}

.page-header {
  margin-bottom: 40px
}

.section-header {
  margin-top: 40px
}

.title-badge {
  display: inline !important;
  margin-left: 30%;
}

//
// TABLE
//

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  border-spacing: 0;
  border-collapse: collapse;


  .table-selectable-row:hover {
    cursor: pointer;
    background-color: #dadada;
  }
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #efefef;
}

.table.table-striped>tbody>tr>td {
  padding: 22px;
}

.table.table-striped>thead>tr>th {
  padding-bottom: 5px;
}

th {
  text-align: left;
  padding-left: 22px;
}

// 
// END TABLE
//
:root{
  --mdc-chip-elevated-container-color : #212121 !important;
  --mat-toolbar-container-text-color: white !important;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: white;
 // background-color: mat.get-color-from-palette($my-primary, 400) !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  //background-color: $dark;
  border-radius: 10px;

  border-width: 0;
  border-style: none;
  border-radius: 0;
}

.section-headline {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 20px 0;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
}

h5 {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
}

.side-card {
  margin-left: 15px;
  margin-right: 15px;
  top: 15px;
}

.sticky {
  position: sticky !important;
}

//
// MATERIAL DESIGN OVERRIDE
//

.mat-mdc-card {
 // color: $dark !important;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !important;
}

.form-card {
  margin-bottom: 20px;
}

// .mat-mdc-card-content {
//   margin: 0 16px !important;
// }


mat-paginator.mat-mdc-paginator.paginator-centered{
  display: flex;
  justify-content: center;
}

.mat-expansion-panel {
  margin-top: 8px !important;
}

.mat-expansion-panel:last-of-type {
  margin-bottom: 8px !important;
}

.mat-expansion-panel-header-title {
  font-weight: bold;
  font-size: 16px;
  color: #666 !important;
}

.cdk-overlay-container {
  .mat-mdc-snack-bar-container.success {
    background: #529200;
    color: #fff;
  }

  .mat-mdc-snack-bar-container.error {
    background: #b50000;
    color: #fff;
  }

}

// 
// END MATERIAL DESIGN
//

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border .2s ease-in-out;
  -o-transition: border .2s ease-in-out;
  transition: border .2s ease-in-out;
}

.thumbnail>img {
  margin-right: auto;
  margin-left: auto;
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  height: 100%;
  max-height: 480px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 250px;
}

.width150 {
  width: 150px;
  height: auto;
}

.width640 {
  width: 640px;
}

.width100 {
  width: 100%;
}


// .sticky-fab {
//   top: auto;
//   right: 20px;
//   bottom: 20px;
//   left: auto;
//   position: fixed !important;
// }

.filter-section {
  margin-top: 25px;
}

.marginBottom2Rem {
  margin-bottom: 2rem !important;
}

.centerAndJustify {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.marginTop2Rem {
  margin-top: 2rem;
}


.full-width {
  width: 100%;
}

.align-center {
  display: flex;
  align-items: center;
}

.centerVerticalAndHoritontal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justifyEnd {
  justify-content: end;
}

.margintopNegative16 {
  margin-top: -16px !important;
}

.Padding10PX {
  padding: 10px;
}

.padding1Rem {
  padding: 1rem;
}

.margin1Rem {
  margin: 1rem 0;
}

.loadingOverlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: white;
}

.padding {
  padding: clamp(24px, 50px, 70px)
}

.emptyTableState {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: white;
}



.example-container {
  margin-bottom: 2rem;
  overflow: auto;
}

table {
  width: 100%;

  td {
    padding: 12px;
  }
}

.partner_logo {
  max-width: 50px;
  margin: 12px 24px;
}

.fabAddBtnBottomRight {
  position: fixed !important; 
  bottom: 25px;
  right: 25px;
}

.fabListBtnBottomRight {
  position: fixed !important;
  bottom: 95px;
  right: 25px;
}

@media (max-width: 768px) {
  .container {
    padding: unset;
  }

  table {
    width: auto;
  }

  td {
    padding: 5px 10px !important;
  }

  .partner_logo {
    margin: 0;
  }

}



.mat-column {
  word-wrap: break-word !important;
  white-space: unset !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: keep-all;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.w40 {
  flex: 0 0 40%;
  width: 40%;
}

.w30 {
  flex: 0 0 30%;
  width: 30%;
}

.w20 {
  flex: 0 0 20%;
  width: 20%;
}

.w50 {
  flex: 0 0 50%;
  width: 50%;
}

.w10 {
  flex: 0 0 10%;
  width: 10%;
}

.w5 {
  flex: 0 0 10%;
  width: 10%;
}

tr td:first-of-type {
  padding-left: 24px;
}

th.mat-mdc-header-cell {
  padding: 12px;
}


.width75 {
  width: 75px;
  height: auto;
}

.oneStepForwardBtn {
  margin-left: 15px;
}


::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: 'Überschrift 1' !important;
}

::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: 'Überschrift 2' !important;
}

::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: 'Überschrift 3' !important;
}

::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: 'Überschrift 4' !important;
}

::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: 'Überschrift 5' !important;
}

// ::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
//   content: 'Überschrift 6' !important;
// }


::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before {
  content: 'Überschrift 1' !important;
}

::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before {
  content: 'Überschrift 2' !important;
}

::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before {
  content: 'Überschrift 3' !important;
}

::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before {
  content: 'Überschrift 4' !important;
}

::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before {
  content: 'Überschrift 5' !important;
}

// ::ng-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before {
//   content: 'Überschrift 6' !important;
// }

::ng-deep .ql-snow .ql-picker.ql-header {
  width: 120px;
}

::ng-deep quill-editor {
  width: 100%;
}

::ng-deep .ql-snow .ql-tooltip[data-mode=link]::before{
  content: "Link:" !important;
}

::ng-deep .ql-snow .ql-tooltip.ql-editing a.ql-action::after{
  content: "Speichern" !important;
}

::ng-deep .mat-mdc-dialog-title{
  h1{
    font-size: 1.5rem !important;
  }
}

.mat-mdc-chip{
  font-size: 14px !important;
}

.primaryChip{
 // background-color: mat.get-color-from-palette($my-primary, 400) !important;
  span{
    color: white;
  }
}
.accentChip{
 // background-color: mat.get-color-from-palette($my-accent, 400) !important;
}

.warnChip{
  background-color: #f44336 !important;
  span{
    color: white;
  }
}

.mat-mdc-card-header{
  margin-bottom: 15px !important;
}