@use '@carbon/styles/scss/theme';
@use '../globals';
@use '../transition';

.#{globals.$prefix}--#{globals.$charts-prefix}--chart-wrapper
	.#{globals.$prefix}--#{globals.$charts-prefix}--tree {
	g.links {
		fill: none;
		stroke: theme.$border-strong-01;
		stroke-opacity: 0.4;
		stroke-width: 1.5;
	}

	g.clickable {
		cursor: pointer;

		&:hover {
			text {
				// TODO-V11
				// font-weight: font-weight('semibold');
				font-weight: 600;
			}

			circle {
				fill: theme.$text-primary;
				@include transition.default_transition;
			}
		}
	}

	circle {
		&.parent {
			fill: theme.$text-secondary;
		}

		&.child {
			fill: theme.$border-strong-01;
		}
	}

	text {
		fill: theme.$text-primary;

		&.text-stroke {
			stroke: theme.$text-inverse;
			stroke-width: 2px;
		}
	}
}
