@use '@carbon/styles/scss/theme';
@use '../globals';

.#{globals.$prefix}--#{globals.$charts-prefix}--scatter-stacked {
	circle.dot.unfilled {
		fill: theme.$layer-01;
		stroke-width: 1.5;
	}

	circle.dot.threshold-anomaly {
		stroke-width: 3;
	}
}
