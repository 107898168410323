@use '../globals';
@use '../tokens';

.#{globals.$prefix}--#{globals.$charts-prefix}--ruler,
.#{globals.$prefix}--#{globals.$charts-prefix}--ruler-binned {
	line.ruler-line {
		stroke: tokens.$layer-inverse-absolute;
		stroke-width: 1px;
		stroke-dasharray: 2;
		pointer-events: none;
	}
}
