@use '@carbon/styles/scss/theme';
@use '../globals';
@use '../tokens';

.#{globals.$prefix}--#{globals.$charts-prefix}--radar {
	.blobs path {
		stroke-width: 1.5px;
	}

	.y-axes path,
	.x-axes line {
		stroke-width: 1px;
		stroke: theme.$layer-accent-01;
	}

	.x-axes line.hovered {
		stroke: tokens.$layer-inverse-absolute;
	}
}
