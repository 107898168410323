@use '@carbon/styles/scss/theme';
@use '../globals';
@use '../tokens';

.#{globals.$prefix}--#{globals.$charts-prefix}--grid {
	rect.chart-grid-backdrop {
		fill: tokens.$grid-bg;
	}

	rect.chart-grid-backdrop.stroked {
		stroke: theme.$layer-accent-01;
	}

	rect.stroke {
		stroke: theme.$layer-accent-01;
	}

	g.x.grid g.tick,
	g.y.grid g.tick {
		line {
			pointer-events: none;
			stroke-width: 1px;
			stroke: theme.$layer-accent-01;
		}

		&.active line {
			stroke-dasharray: 2px;
			stroke: theme.$focus;
		}
	}
}
